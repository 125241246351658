import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@ayx/onyx-ui';
import { Trash, DownloadCloud, DataInsights } from '@ayx/icons';
import { Component } from 'react';
import JobDetailsDialog from "./JobDetailsDialog";

interface IJobListProps {
  baseUrl: string;
}

export interface IJobListDetails {
  jobId: string;
  startUTC: string;
  endUTC: string;
  executionId: string;
  status: string;
  message: string;
  serviceName: string;
  image: string;
}

class JobList extends Component<IJobListProps, any> {
  constructor(props: any) {
    super(props);
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      error: null,
      isLoaded: false,
      jobs: [],
      snackbarMessage: null,
      snackbarOpen: false,
      snackbarSeverity: 'success',
      jobDetails: null,
      jobDetailsDialogOpen: false
    };
  }

  handlejobDetailsDialogOpen = async (jobId: string) => {
    this.setState({ jobDetails: null, jobDetailsDialogOpen: true });
    const jobDetails = await this.getJobDetails(jobId);
    this.setState({ jobDetails });
  }

  handlejobDetailsDialogClose = () => {
    this.setState({ jobDetailsDialogOpen: false });
  }

  async getJobDetails(jobId: string) {
    return fetch(`${this.props.baseUrl}/api/v1/job/${jobId}`, {
      method: 'GET',
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => {
        return response.json();
      })
      .catch(error => {
        this.showSnackBar('error', 'Failed to fetch job details!');
        console.log(error);
      });
  }

  showSnackBar(snackbarSeverity: any, snackbarMessage: any) {
    this.setState({ snackbarMessage, snackbarOpen: true, snackbarSeverity });
    setTimeout(() => {
      this.setState({ snackbarOpen: false });
    }, 3000);
  }

  deleteJob(jobId: any) {
    console.log(`delete: ${jobId}`);
    this.showSnackBar('success', 'Job requested!');

    fetch(`${this.props.baseUrl}/api/v1/job/${jobId}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => {
        console.log('run response', response);
        this.showSnackBar('success', 'Job deleted!');
        this.componentDidMount();
      })
      .catch(error => {
        this.showSnackBar('error', 'Job delete failed!');
        console.log(error);
      });
  }

  componentDidMount() {
    fetch(`${this.props.baseUrl}/api/v1/jobs`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            jobs: result,
          });
        },
        error => {
          this.setState({
            error,
            isLoaded: true,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, jobs } = this.state;

    if (error) {
      // eslint-disable-next-line 
      return <div>Error: {error.message}</div>;
    }
    return (
      <>
        <Typography variant="h2">Jobs</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Job ID</TableCell>
                <TableCell>Workflow Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Completed At</TableCell>
                <TableCell>Engine</TableCell>
                <TableCell>Result</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((job: any) => (
                <TableRow key={job.id}>
                  <TableCell component="th" scope="row">
                    {job.id}
                  </TableCell>
                  <TableCell>{job.workflowName}</TableCell>
                  <TableCell>{job.submittedUtc}</TableCell>
                  <TableCell>{job.completedUtc}</TableCell>
                  <TableCell>Snowpark Containers</TableCell>
                  <TableCell>
                    <Button color="primary" onClick={() => {
                      window.open(`${this.props.baseUrl}/api/v1/job/${job.id}/result`, '_blank');
                      return false;
                    }} startIcon={<DownloadCloud />} style={{ marginLeft: 5 }} variant="contained">
                      Results
                    </Button>
                  </TableCell>
                  <TableCell>
                    {/* <Button color="secondary" onClick={() => {
                      window.open(`${this.props.baseUrl}/api/v1/job/${job.id}`, '_blank');
                      return false;
                    }} startIcon={<DataInsights />} style={{ marginLeft: 5 }} variant="contained">
                      View
                    </Button> */}
                    <Button
                      color="secondary"
                      onClick={() => this.handlejobDetailsDialogOpen(job.id)}
                      startIcon={<DataInsights />}
                      style={{ marginLeft: 5 }}
                      variant="contained"
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button color="secondary" onClick={() => this.deleteJob(job.id)} startIcon={<Trash />} variant="contained">
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button color="primary" onClick={() => this.componentDidMount()} style={{ marginTop: 25 }} variant="contained">
          Refresh
        </Button>

        <JobDetailsDialog 
          handleClose={this.handlejobDetailsDialogClose}
          jobDetails={this.state.jobDetails}
          open={this.state.jobDetailsDialogOpen}
        />
      </>
    );
  }
}

export default JobList;
