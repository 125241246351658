import {
  Alert,
  Button,
  Paper,
  Snackbar,
  TextField,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box
} from '@ayx/onyx-ui';
import { Trash, Play, DownloadCloud, User } from '@ayx/icons';
import React, { Component } from 'react';

import DesignerFileIcon from './DesignerFileIcon';

interface IWorkflowListProps {
  baseUrl: string;
}

const defaultEngineOption = 'Snowpark Containers';

const engineSelectProps = {
  options: [
    defaultEngineOption,
    'Alteryx Analytics Cloud'
  ]
};

class WorkflowList extends Component<IWorkflowListProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      hosts: [],
      isLoaded: false,
      snackbarMessage: null,
      snackbarOpen: false,
      snackbarSeverity: 'success',
    };
  }

  showSnackBar(snackbarSeverity: any, snackbarMessage: any) {
    this.setState({ snackbarMessage, snackbarOpen: true, snackbarSeverity });
    setTimeout(() => {
      this.setState({ snackbarOpen: false });
    }, 3000);
  }

  runWorkflow(workflowId: any) {
    console.log(`run: ${workflowId}`);
    this.showSnackBar('success', 'Job requested!');

    fetch(`${this.props.baseUrl}/api/v1/job/${workflowId}`, {
      method: 'POST',
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => {
        console.log('run response', response);
        this.showSnackBar('success', 'Job submitted!');
      })
      .catch(error => {
        this.showSnackBar('error', 'Job request failed!');
        console.log(error);
      });
  }

  deleteWorkflow(workflowId: any) {
    console.log(`delete: ${workflowId}`);
    this.showSnackBar('success', 'Job requested!');

    fetch(`${this.props.baseUrl}/api/v1/workflow/${workflowId}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => {
        console.log('run response', response);
        this.showSnackBar('success', 'Workflow deleted!');
        this.componentDidMount();
      })
      .catch(error => {
        this.showSnackBar('error', 'Workflow delete failed!');
        console.log(error);
      });
  }

  componentDidMount() {
    fetch(`${this.props.baseUrl}/api/v1/workflows`)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            hosts: result,
            isLoaded: true,
          });
        },
        error => {
          this.setState({
            error,
            isLoaded: true,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, hosts } = this.state;
    if (error) {
      // eslint-disable-next-line 
      return <div>Error: {error.message}</div>;
    }

    return (
      <>
        <Typography variant="h2">Designer Desktop</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Workflow Name</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>Engine</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hosts.map((workflow: any) => (
                <TableRow key={workflow.id}>
                  <TableCell>
                    <DesignerFileIcon workflowName={workflow.name} />
                  </TableCell>
                  <TableCell>{workflow.filename}</TableCell>
                  <TableCell>
                    <User size={30} />
                    <Box style={{
                      display: "inline-block",
                      position: "relative",
                      top: "-10px",
                      left: "8px"
                    }}>Bob Smith</Box>
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      {...engineSelectProps}
                      defaultValue={defaultEngineOption}
                      disableClearable
                      getOptionDisabled={option => option === 'Alteryx Analytics Cloud'}
                      renderInput={params => (
                        <TextField {...params} fullWidth id="ac-disableClearable" margin="normal" />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Button color="primary" onClick={() => this.runWorkflow(workflow.id)} startIcon={<Play />} variant="contained">
                      Run
                    </Button>
                    <Button color="secondary" onClick={() => this.deleteWorkflow(workflow.id)} startIcon={<Trash />} style={{ marginLeft: 5 }} variant="contained">
                      Delete
                    </Button>
                    <Button color="secondary" onClick={() => {
                      window.open(`${this.props.baseUrl}/api/v1/workflow/${workflow.id}/download`, '_blank');
                      return false;
                    }} startIcon={<DownloadCloud />} style={{ marginLeft: 5 }} variant="contained">
                      Download
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open={this.state.snackbarOpen}>
          <Alert severity={this.state.snackbarSeverity}>{this.state.snackbarMessage}</Alert>
        </Snackbar>
      </>
    );
  }
}

export default WorkflowList;
