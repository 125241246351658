import {
  Box,
  makeStyles
} from '@ayx/onyx-ui';
import { Designer, FileIcon } from '@ayx/onyx-ui-lab';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex'
  }
});

const DesignerFileIcon = (props: any) => {
  const classes = useStyles();

  return (
    <Box style={{ display: 'flex' }}>
      <FileIcon classes={{
        root: classes.root,
      }}>
        <Designer />
      </FileIcon>
      <Box style={{ display: 'flex', marginTop: 5, marginLeft: 5 }}>
        {props.workflowName.replace(".yxzp", "")}
      </Box>
    </Box>
  );
};

//position: 'relative', top: -28, left: 36

export default DesignerFileIcon;