import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Snackbar,
  Typography,
} from '@ayx/onyx-ui';
import { withStyles } from '@ayx/onyx-ui/styles';
import React, { Component } from 'react';

const styles = (theme: any) => ({
  formInput: {
    width: 300,
  },
  formRow: {
    marginTop: 20,
  },
});

interface IUploadProps {
  baseUrl: string;
  classes?: any;
}

class Upload extends Component<IUploadProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      file: '',
      fileInputError: false,
      name: '',
      nameInputError: false,
      snackbarMessage: null,
      snackbarOpen: false,
      snackbarSeverity: 'success',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async validateFormAndGetData() {
    const { file, name, primaryWorkflowPath } = this.state;
    const fileInputError = file === '';
    const nameInputError = name === '';

    const formData = {
      file,
      name
    };

    this.setState({ fileInputError, nameInputError });
    if (fileInputError || nameInputError ) {
      return false;
    }
    return Promise.resolve(formData);
  }

  handleChange(event: any) {
    const value = event.target.id === 'file' ? event.target.files[0] : event.target.value.trim();
    this.setState({
      [event.target.id]: value,
    });
  }

  handleSubmit = async (event: any) => {
    event.preventDefault();

    const formData: any = await this.validateFormAndGetData();
    if (formData) {
      const data: any = new FormData();
      data.append('file', formData.file);
      data.append('name', formData.name);

      return fetch(`${this.props.baseUrl}/api/v1/workflow`, {
        body: data,
        method: 'POST',
      })
        .then(response => {
          if (!response.ok) {
            this.showSnackBar('error', 'Unable to upload!');
            throw Error(response.statusText);
          }
          return response;
        })
        .then(() => {
          this.showSnackBar('success', 'File uploaded!');
        })
        .catch(error => {
          console.log(error);
          this.showSnackBar('error', 'Unable to upload!');
        });
    }

    event.target.reset();
    return false;
  };

  showSnackBar(snackbarSeverity: any, snackbarMessage: any) {
    this.setState({ snackbarMessage, snackbarOpen: true, snackbarSeverity });
    setTimeout(() => {
      this.setState({ snackbarOpen: false });
    }, 3000);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">Upload Workflow</Typography>
          </Grid>
          <form encType="m" onSubmit={this.handleSubmit}>
            <Grid item xs={12}>
              <FormControl className={classes.formRow} error={this.state.fileInputError}>
                <InputLabel htmlFor="file">File to Upload</InputLabel>
                <Input className={classes.formInput} id="file" onChange={this.handleChange} type="file" />
                <FormHelperText>This will be a YXZP file from Designer Desktop, for this demo this is a json file.</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formRow} error={this.state.nameInputError}>
                <InputLabel htmlFor="name">Name</InputLabel>
                <Input
                  autoComplete="off"
                  className={classes.formInput}
                  id="name"
                  onChange={this.handleChange}
                  type="text"
                />
                <FormHelperText>Friendly display name for this workflow</FormHelperText>
              </FormControl>
            </Grid>
            <Button
              color="primary"
              onSubmit={this.handleSubmit}
              style={{ marginTop: 25 }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
        </Grid>

        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open={this.state.snackbarOpen}>
          <Alert severity={this.state.snackbarSeverity}>{this.state.snackbarMessage}</Alert>
        </Snackbar>
      </>
    );
  }
}

export default withStyles(styles)(Upload);
