import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Grid,
    useMediaQuery,
    useTheme
  } from '@ayx/onyx-ui';
  import { IJobListDetails } from "./JobList";

  interface IJobDetailsDialogProps {
    open: any;
    handleClose: any;
    jobDetails: null | IJobListDetails;
  }

  function renderJobDetailsTable(jobDetails: IJobListDetails) {
    const { 
        jobId,
        startUTC,
        endUTC,
        executionId,
        status,
        message,
        serviceName,
        image,
    } = jobDetails;

    return (
        <>
            <TableRow>
                <TableCell><strong>Job ID</strong></TableCell>
                <TableCell>{jobId}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell><strong>Execution Id</strong></TableCell>
                <TableCell>{executionId}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell><strong>Service Name</strong></TableCell>
                <TableCell>{serviceName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell><strong>Docker Image</strong></TableCell>
                <TableCell>{image}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell><strong>Start UTC</strong></TableCell>
                <TableCell>{startUTC}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell><strong>End UTC</strong></TableCell>
                <TableCell>{endUTC}</TableCell>
            </TableRow>
            {/* These next two props always appear to fail, but really they don't. Add back once we fix the UEW return code. */}
            {/* <TableRow>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell>{status}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell><strong>Message</strong></TableCell>
                <TableCell>{message}</TableCell>
            </TableRow> */}
        </>
    );
  }
  
  const JobDetailsDialog = (props: IJobDetailsDialogProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { open, handleClose, jobDetails } = props;

    return (
      <Grid container justifyContent="center">
        <Dialog aria-labelledby="responsive-dialog-title" fullScreen={fullScreen} onClose={handleClose} open={open}>
          <DialogTitle id="responsive-dialog-title">Job Details</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                <TableRow>
                    <TableCell width={110}>Property</TableCell>
                    <TableCell>Value</TableCell>
                </TableRow>
                </TableHead>
                    <TableBody>
                        {jobDetails === null        
                            ? <TableRow><TableCell>Loading...</TableCell></TableRow>
                            : renderJobDetailsTable(jobDetails)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={handleClose} variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };
  
  export default JobDetailsDialog;
  