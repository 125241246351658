import { AyxAppWrapper, Grid, Box } from '@ayx/onyx-ui';
import { Component } from 'react';

import Header from './Header';
import JobList from './JobList';
import Upload from './Upload';
import WorkflowList from './WorkflowList';

// const baseUrl = 'https://platform.alter-labs.com';
// const baseUrl = 'https://localhost:7224';
const baseUrl = '';

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      current: 'Upload',
    };
  }

  setCurrentView = (newComponent: any) => {
    this.setState({
      current: newComponent,
    });
  };

  render() {
    const currentView = this.state.current;
    return (
      <AyxAppWrapper>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Header setCurrentView={this.setCurrentView} />
          </Grid>
          <Grid item xs={12}>
            <Box style={{ margin: "0 50px" }}>
              {currentView === 'Upload' && <Upload baseUrl={baseUrl} />}
              {currentView === 'Files' && <WorkflowList baseUrl={baseUrl} />}
              {currentView === 'Jobs' && <JobList baseUrl={baseUrl} />}
            </Box>
          </Grid>
        </Grid>
      </AyxAppWrapper>
    );
  }
}

export default App;
