import { Feedback, LogOut, Shuffle, User } from '@ayx/icons';
import { Tab, Tabs } from '@ayx/onyx-ui';
import { GlobalAppBar, ProductSwitcher } from '@ayx/onyx-ui-lab';
import {
  AnalyticsCloudLogo,
  AppBuilderLogo,
  AutoInsightsLogo,
  DesignerCloudLogo,
  MachineLearningLogo,
  ReportingLogo,
  SpatialLogo
} from '@ayx/product-logos';
import React, { useMemo, useState } from 'react';

const products = [
  { ProductLogo: DesignerCloudLogo },
  { ProductLogo: AppBuilderLogo },
  { ProductLogo: AutoInsightsLogo },
  { ProductLogo: MachineLearningLogo },
  { ProductLogo: ReportingLogo },
  { ProductLogo: SpatialLogo }
];

const helpMenuItems = [
  {
    divider: true,
    menuItemText: 'About',
  },
  {
    menuItemText: 'Community',
  },
  {
    ListItemIcon: <Feedback />,
    menuItemText: 'Feedback',
  },
];

const Header = (props: any) => {
  const tabs = ["Upload", "Files", "Jobs"];
  const [value, setValue] = useState(0);

  const handleTabChange = (_, newValue) => {
    const tabViewName = tabs[newValue];
    setValue(newValue);
    props.setCurrentView(tabViewName);
  };

  const settingsMenuItems = useMemo(
    () => [
      {
        ListItemIcon: <Shuffle />,
        divider: true,
        menuItemSecondaryText: 'Workspace Name',
        menuItemText: 'Switch Workspace',
        subMenu: [],
      },
      {
        ListItemIcon: <User />,
        divider: true,
        menuItemText: 'Admin',
      },
      {
        ListItemIcon: <LogOut />,
        menuItemText: 'Sign Out',
      },
    ],
    []
  );

  const appBarContent = [
    <Tabs key="tab" onChange={handleTabChange} value={value}>
      {tabs.map((t, i) => {
        return <Tab key={i} label={t} />;
      })}
    </Tabs>,
  ];

  return (
    <GlobalAppBar
      appBarContent={appBarContent}
      helpMenuItems={helpMenuItems}
      ProductIcon={<AnalyticsCloudLogo margin="none" size={34} />}
      ProductSwitcher={<ProductSwitcher homeHref='/' products={products} />}
      settingsMenuItems={settingsMenuItems}
      userInfo={{
        avatarImage: 'public/static/images/andrewwk.jpg',
        email: 'example_customer@alteryx.com',
        name: 'Example Customer'
      }}
    />
  );
};

export default Header;